<template>
  <div class="container">
    <div class="handle-box">
      <el-input
        v-model="query.lessonName"
        placeholder="Lesson Name"
        class="handle-input mr10"
        :clearable="true"
        @input="handleLessonNameQuery"
      ></el-input>
    </div>
    <el-table
      :data="filterData()"
      border
      class="table"
      header-cell-class-name="table-header"
    >
      <el-table-column prop="displayOrder" label="Week" width="80">
        <template #default="scope">
          {{ scope.row.displayOrder }}
        </template>
      </el-table-column>
      <el-table-column prop="lessonName" label="Lesson Name">
        <template #default="scope">
          <el-tag>{{ scope.row.lessonName }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  name: "lessonsInClassRoom",
  props: {
    roomId: String,
  },
  data() {
    return {
      controllerUrl: "/classRoom",
      query: {
        roomId: null,
        lessonName: null,
      },
      tableData: [],
    };
  },
  watch: {
    roomId(newValue) {
      if (newValue) {
        if (newValue !== this.query.roomId) {
          this.initAndLoadData(newValue);
        }
      }
    },
  },
  created() {
    this.initAndLoadData(this.roomId);
  },
  methods: {
    initAndLoadData(roomId) {
      this.query.roomId = roomId;
      this.loadData();
    },
    loadData() {
      getData(
        `${this.controllerUrl}/getAllLessonsInClassRoom/${this.query.roomId}`
      ).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
      });
    },
    filterData() {
      let recordList = this.tableData;
      if (this.query.lessonName) {
        recordList = recordList.filter(
          (e) => e.lessonName.indexOf(this.query.lessonName) >= 0
        );
      }

      return recordList;
    },
    handleLessonNameQuery(val) {
      console.log("handleLessonNameQuery", val);
      this.query.lessonName = val;
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
