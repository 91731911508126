<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          My Classes
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.grade"
          placeholder="Grade"
          class="mr10"
          :clearable="true"
        >
          <el-option
            v-for="item in options.gradeList"
            :key="item.description"
            :label="item.description"
            :value="item.description"
          ></el-option>
        </el-select>
        <el-select
          v-model="query.term"
          placeholder="Term"
          class="mr10"
          :clearable="true"
        >
          <el-option
            v-for="item in options.termList"
            :key="item.description"
            :label="item.description"
            :value="item.description"
          ></el-option>
        </el-select>
      </div>
      <el-table
        :data="filterData()"
        border
        class="table"
        header-cell-class-name="table-header"
        :row-class-name="classRoomTableRowClassName"
      >
        <el-table-column prop="classRoom.name" label="Name"></el-table-column>
        <el-table-column
          prop="classRoom.grade"
          label="Grade"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="classRoom.term"
          label="Term"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="classRoom.startDate"
          label="Start Date"
          width="120"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          prop="classRoom.endDate"
          label="End Date"
          width="120"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column label="Status" width="120">
          <template #default="scope">
            <el-tag
              class="ml-2"
              :type="
                showMyClassRoomActiveStatusTagType(scope.$index, scope.row)
              "
            >
              {{ scope.row.classRoom.activeStatusText }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Operation" width="100" align="center">
          <template #default="scope">
            <el-button
              type="text"
              @click="showLessons(scope.$index, scope.row)"
            >
              <el-icon>
                <notebook />
              </el-icon>
              Lessons
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        :title="showLessonsDialogTitle"
        v-model="showLessonsDialog"
        width="60%"
      >
        <lessons-in-class-room
          :roomId="currentClassRoom.roomId"
          :destroy-on-close="true"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";
import LessonsInClassRoom from "./LessonsInClassRoom.vue";

export default {
  components: { LessonsInClassRoom },
  name: "classRoomList",
  data() {
    return {
      controllerUrl: "/classRoom",
      query: {
        grade: null,
        term: null,
      },
      options: {
        gradeList: [],
        termList: [],
      },
      tableData: [],
      dialogTitle: "",
      currentClassRoom: null,
      showLessonsDialog: false,
      showLessonsDialogTitle: "",
      form: {},
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;

    this.loadData();
  },
  methods: {
    loadData() {
      getData(
        `${this.controllerUrl}/getEngagedClassRoomsForCurrentStudent`
      ).then((res) => {
        let resData = res.result;
        let list = resData.list;
        list.sort((x, y) => {
          return y.classRoom.activeStatus - x.classRoom.activeStatus;
        });
        this.tableData = list;
      });
    },
    filterData() {
      let recordList = this.tableData;
      if (this.query.grade) {
        recordList = recordList.filter(
          (e) => e.classRoom.grade === this.query.grade
        );
      }
      if (this.query.term) {
        recordList = recordList.filter(
          (e) => e.classRoom.term === this.query.term
        );
      }
      return recordList;
    },
    showMyClassRoomActiveStatusTagType(index, row) {
      if (row.classRoom.activeStatus === 2048) {
        return "";
      }
      if (row.classRoom.activeStatus === 1) {
        return "info";
      }
      return "success";
    },
    showLessons(index, row) {
      this.currentClassRoom = row.classRoom;
      this.showLessonsDialogTitle = `My lessons for the class room '${this.currentClassRoom.name}'`;
      this.showLessonsDialog = true;
    },
    classRoomTableRowClassName({ row }) {
      if (row.classRoom.activeStatus === 2048) {
        return "active-row";
      } else if (row.classRoom.activeStatus === 1) {
        return "";
      }
      return "unstarted-row";
    },
  },
};
</script>
<style>
.el-table .active-row {
  background: #ffa500;
}
.el-table .unstarted-row {
  background: #9bcaf9;
}
</style>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
